<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>

      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal" v-if="warehouseName">
            {{ warehouseName }} Stock Intake
          </h2>
          <h2 class="ng-block-title fw-normal" v-else>
            {{ $route.meta.title }}
          </h2>

          <div class="ng-block-des">
            <h6>
              Week {{GetWeek(start)}} (<b>
                {{ fD(start, "E, do MMM") }}
              </b>
              -
              <b> {{ fD(end, "E, do MMM") }} </b>).
              <!--              <router-link-->
              <!--                class="btn btn-sm btn-icon btn-round btn-secondary d-print-none"-->
              <!--                :to="`/picks/week/${fD(start, 'yyyy-MM-dd')}`"-->
              <!--              >-->
              <!--                <em class="icon ni ni-chart-up"></em>-->
              <!--              </router-link>-->
            </h6>
          </div>
        </div>

        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li v-if="warehouse">
              <div
                class="custom-control custom-control-sm custom-checkbox notext"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="view-reports"
                  v-model="reports"
                />
                <label class="custom-control-label" for="view-reports">
                  Hide Empty
                </label>
              </div>
            </li>
            <li v-if="warehouses.length">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span
                    class="
                      input-group-text
                      bg-primary
                      text-white
                      font-weight-bold
                    "
                  >
                    Warehouse
                  </span>
                </div>
                <Multiselect
                  :options="warehouses"
                  v-model="warehouse"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Type to search..."
                  @change="fetchVarieties"
                />
              </div>
            </li>
            <li v-if="warehouse">
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Start </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Start Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Week </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div
        class="card"
        :class="{ 'card-bordered': warehouse && varieties.length }"
        v-else
      >
        <div class="card-body" v-if="warehouse">
          <ul class="nav nav-tabs">
            <li
              class="nav-item"
              v-for="(d, i) in getDates(start, end)"
              :key="i"
            >
              <a
                class="nav-link"
                :class="{ active: i == day }"
                data-toggle="tab"
                :href="`#day${i}`"
                @click="setDay(i, d)"
              >
                {{ fD(new Date(d), "E, do MMM") }}
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              v-for="(d, i) in getDates(start, end)"
              :key="i"
              class="tab-pane"
              :id="`day${i}`"
              :class="{ active: i == day }"
            >
              <div class="ng-block ng-block-lg">
                <div class="ng-block-head px-4">
                  <div class="ng-block-head-content">
                    <h5 class="ng-block-title d-none">
                      {{ fD(new Date(d)) }}
                    </h5>

                    <div
                      class="ng-block-des d-print-none d-flex justify-content-between"
                    >
                      <p>
                        <b class="float-left">
                          {{ warehouseName }};
                          {{ fD(new Date(d)) }}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
                <form
                  class="card w-100"
                  method="POST"
                  @submit.prevent="savePicks"
                >
                  <div class="card-body row">
                    <div
                      class="col-md-3"
                      style="overflow-y: scroll; max-height: 40vh"
                    >
                      <ul
                        class="nav nav-tabs flex-column"
                        id="myTab"
                        role="tablist"
                      >
                        <li
                          class="nav-item"
                          v-for="(p, i) in varieties"
                          :key="i"
                        >
                          <a
                            class="nav-link"
                            :class="{
                              active: p.id == prod
                            }"
                            :id="`tab${p.id}`"
                            data-toggle="tab"
                            :href="`#pack${p.id}`"
                            role="tab"
                            :aria-controls="`pack${p.id}`"
                            :aria-selected="p.id == prod"
                            @click="setProd(p.id)"
                          >
                            {{ p.name }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-9 tab-content" id="myTabContent">
                      <div
                        v-for="(p, i) in varieties"
                        :key="i"
                        class="tab-pane fade show"
                        :class="{
                          active: p.id === prod
                        }"
                        :id="`pack${p.id}`"
                        role="tabpanel"
                        :aria-labelledby="`tab${p.id}`"
                      >
                        <a
                          v-if="form[prevDay(d)][p.id]['stock_records_present']"
                          href="#stockForward"
                          data-toggle="modal"
                          class="btn btn-primary mb-3"
                          @click="selectStockByVariety(p, d)"
                        >
                          Bring Stock Forward
                        </a>
                        <a
                          v-else
                          class="btn btn-outline-secondary disabled mb-3"
                          >No Stock to Bring Forward</a
                        >
                        <div class="card card-bordered" v-if="form[d][p.id]">
                          <div class="card-header">
                            <h5 class="card-title">
                              {{ p.name }}
                              <span
                                class="float-right"
                                v-if="form[d][p.id]['projected'] !== 0"
                              >
                                Current Value:
                                {{ form[d][p.id]["projected"] }}
                              </span>
                            </h5>
                          </div>
                          <div class="card-body row">
                            <div class="form-group col-md-6">
                              <label> Packed Ahead </label>
                              <div class="input-group">
                                <input
                                  class="form-control"
                                  type="number"
                                  v-model="form[d][p.id]['packed_ahead']"
                                />
                              </div>
                            </div>

                            <div class="form-group col-md-6">
                              <label> Packed Behind </label>
                              <div class="input-group">
                                <input
                                  class="form-control"
                                  type="number"
                                  v-model="form[d][p.id]['packed_behind']"
                                />
                              </div>
                            </div>

                            <template v-if="form[d][p.id]['records']">
                              <div
                                class="row"
                                v-for="(rep, r) in form[d][p.id]['records']"
                                :key="r"
                              >
                                <div class="form-group col-md-4">
                                  <label> Source </label>
                                  <div class="input-group">
                                    <Multiselect
                                      :options="farms"
                                      v-model="
                                        form[d][p.id]['records'][r]['source']
                                      "
                                      trackBy="name"
                                      style="margin-left: 0"
                                      label="name"
                                      valueProp="id"
                                      :searchable="true"
                                      placeholder="Search Farm..."
                                    />
                                  </div>
                                </div>

                                <div class="form-group col-md-4">
                                  <label> Harvest Date </label>
                                  <div class="input-group">
                                    <input
                                      class="form-control"
                                      type="date"
                                      v-model="
                                        form[d][p.id]['records'][r][
                                          'harvest_date'
                                        ]
                                      "
                                    />
                                  </div>
                                </div>

                                <div class="form-group col-md-4">
                                  <label> Block </label>
                                  <div class="input-group">
                                    <input
                                      class="form-control"
                                      type="text"
                                      v-model="
                                        form[d][p.id]['records'][r]['block']
                                      "
                                    />
                                  </div>
                                </div>

                                <div class="form-group col-md-4">
                                  <label> Max Holding Days </label>
                                  <div class="input-group">
                                    <input
                                      class="form-control"
                                      type="number"
                                      readonly
                                      v-model="
                                        form[d][p.id]['records'][r][
                                          'max_holding_days'
                                        ]
                                      "
                                    />
                                  </div>
                                </div>

                                <div class="form-group col-md-4">
                                  <label> Stock (kg) </label>
                                  <div class="input-group">
                                    <input
                                      class="form-control"
                                      type="number"
                                      v-model="
                                        form[d][p.id]['records'][r]['stock']
                                      "
                                    />
                                  </div>
                                </div>

                                <div class="form-group col-md-4">
                                  <label> No of Crates </label>
                                  <div class="input-group">
                                    <input
                                      class="form-control"
                                      type="number"
                                      v-model="
                                        form[d][p.id]['records'][r]['crates']
                                      "
                                    />
                                  </div>
                                </div>

                                <div class="form-group col-md-12">
                                  <label> Comments </label>
                                  <textarea
                                    class="form-control"
                                    v-model="
                                      form[d][p.id]['records'][r]['comments']
                                    "
                                  >
                                  </textarea>
                                </div>

                                <div
                                  class="form-group col-md-4 align-self-center text-center"
                                >
                                  <button
                                    class="btn btn-icon btn-round btn-danger mr-1"
                                    v-if="r > 0"
                                    @click.prevent="
                                      delRep(form[d][p.id]['records'], r)
                                    "
                                  >
                                    <em class="icon ni ni-minus"></em>
                                  </button>
                                  <button
                                    class="btn btn-icon btn-round btn-primary"
                                    v-if="
                                      r == form[d][p.id]['records'].length - 1
                                    "
                                    @click.prevent="
                                      addRep(
                                        form[d][p.id]['records'],
                                        p.max_holding_days
                                      )
                                    "
                                  >
                                    <em class="icon ni ni-plus"></em>
                                  </button>
                                </div>
                              </div>
                            </template>
                            <template v-else>
                              <div
                                class="form-group col-md-4 align-self-center text-center"
                              >
                                <button
                                  class="btn btn-icon btn-round btn-primary"
                                  @click.prevent="
                                    addNewRep(form[d][p.id], p.max_holding_days)
                                  "
                                >
                                  <em class="icon ni ni-plus"></em>
                                </button>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body justify-content-between">
                    <router-link
                      :to="`/stock/day/${d}?warehouse=${warehouse}`"
                      class="btn btn-outline-light btn-sm"
                    >
                      <em class="icon ni ni-save"> </em>
                      <span>
                        View Stock Summary for
                        {{ fD(new Date(d), "EEEE") }}
                      </span>
                    </router-link>
                  </div>
                </form>
              </div>
            </div>

            <div class="modal fade" tabindex="-1" id="stockForward">
              <div
                class="modal-dialog modal-xl"
                style="max-width: 1280px !important;"
                role="document"
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  id="closeForwardModal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <form class="modal-content">
                  <div class="card-body" v-if="form[prev_date]">
                    <div class="card card-bordered">
                      <div class="card-header">
                        <h5 class="card-title">
                          {{ selected_variety?.name }}
                        </h5>
                      </div>
                      <div
                        class="card-body row"
                        v-for="(rep, r) in form[prev_date][selected_variety.id][
                          'records'
                        ]"
                        :key="r"
                      >
                        <div
                          class="form-group col-md-1 align-self-center text-center"
                          v-if="
                            form[prev_date][selected_variety.id]['records'][r][
                              'source'
                            ] != null
                          "
                        >
                          <input
                            type="checkbox"
                            class="align-items-end"
                            @change="
                              moveStockForward(
                                $event,
                                form[prev_date][selected_variety.id]['records'][
                                  r
                                ]
                              ),
                                r
                            "
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label> Source </label>
                          <div class="input-group">
                            <input
                              class="form-control"
                              type="number"
                              readonly
                              v-model="
                                form[prev_date][selected_variety.id]['records'][
                                  r
                                ]['source']
                              "
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-2">
                          <label> Harvest Date </label>
                          <div class="input-group">
                            <input
                              class="form-control"
                              readonly
                              v-model="
                                form[prev_date][selected_variety.id]['records'][
                                  r
                                ]['harvest_date']
                              "
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-1">
                          <label> Block </label>
                          <div class="input-group">
                            <input
                              class="form-control"
                              type="number"
                              readonly
                              v-model="
                                form[prev_date][selected_variety.id]['records'][
                                  r
                                ]['block']
                              "
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-2">
                          <label> Max Holding Days </label>
                          <div class="input-group">
                            <input
                              class="form-control"
                              type="number"
                              readonly
                              v-model="
                                form[prev_date][selected_variety.id]['records'][
                                  r
                                ]['max_holding_days']
                              "
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-2">
                          <label> Stock </label>
                          <div class="input-group">
                            <input
                              class="form-control"
                              type="number"
                              v-model="
                                form[prev_date][selected_variety.id]['records'][
                                  r
                                ]['stock']
                              "
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-2">
                          <label> No of crates </label>
                          <div class="input-group">
                            <input
                              class="form-control"
                              type="number"
                              v-model="
                                form[prev_date][selected_variety.id]['records'][
                                  r
                                ]['crates']
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn btn-primary py-2 m-2 text-center"
                        @click.prevent="saveStockForward"
                      >
                        <span>Bring Forward</span>
                      </button>
                    </div>
                  </div>
                  <div v-else>No values for {{ prev_date }}</div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>

          Please select a warehouse to set forecasts for.
        </div>
      </div>
    </div>

    <div
      class="ng-block d-print-none justify-content-between"
      v-if="varieties.length"
    >
      <!--            <router-link-->
      <!--              class="btn btn-secondary"-->
      <!--              :to="`/picks/week/${fD(start, 'yyyy-MM-dd')}`"-->
      <!--            >-->
      <!--              Week {{GetWeek(start)}} Analysis-->
      <!--            </router-link>-->

      <button class="btn btn-primary float-right disabled" v-if="loading">
        <em class="icon ni ni-save"></em>
        <span> Save Week's Stock</span>
      </button>

      <button
        v-else
        class="btn btn-primary float-right"
        @click.prevent="saveStock"
      >
        <em class="icon ni ni-save"></em>
        <span> Save Week's Stock</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import type { Ref } from 'vue'
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: {NgongVegBanner},
  setup(props) {
    const form = ref({});

    const reports = ref(false);

    const tosave = ref();

    const forward_stock: Ref<any> = ref([]);

    const warehouses = ref([]);
    const farms = ref([]);
    const warehouse = ref(0);
    const warehouseName = computed(() => {
      const n = warehouses.value?.find((f: any) => f.id == warehouse.value);

      return n?.["name"];
    });

    const varieties = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const loading = ref(false);

    const day = ref(0);
    const date = ref(new Date());
    const prod = ref(1);

    function tuesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 2
          ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 1))
          : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 1));
    }

    const start_prev = ref(tuesday());

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }
    const start = ref(new Date());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function prevDay(today: any) {
      const date = moment(today);
      return date.subtract(1, "days").format("YYYY-MM-DD");
    }

    function nextDay(date: any){
      const new_date = moment(date)
      return new_date.add(1, "days").format("YYYY-MM-DD");
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function getFarmName(id: string){
      const selected_farm: any =  farms.value.filter(function (farm: any) {
        return farm.id = id
      })
      return selected_farm.name
    }

    const prev_date = ref(fD(start_prev.value, "yyyy-MM-dd"));
    const selected_variety = ref({
      name: 'N',
      id: '1',
    });

    function changePrevDate(s: any){
      const newer = moment(s).subtract(1, "days").format("YYYY-MM-DD")
      start_prev.value = new Date(newer)
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function setProd(n: 0) {
      prod.value = n;
    }

    function addRep(u: any, mhd: any) {
      u.push({
        source: "",
        harvest_date: "",
        dnote: 0,
        block: 0,
        max_holding_days: mhd,
        stock: 0,
        crates: 0,
        comments: ""
      });
    }

    function addNewRep(u: any, mhd: any) {
      u['records'] = [{
        source: "",
        harvest_date: "",
        dnote: 0,
        block: 0,
        max_holding_days: mhd,
        stock: 0,
        crates: 0,
        comments: ""
      }]
    }

    function delRep(u: any, i: any) {
      u.splice(i, 1);
    }

    function moveStockForward(e: any, record: any, index: any){
      if(e.target.checked){
        forward_stock.value.splice(index, 0, record)
      }else {
        forward_stock.value.splice(index, 1)
      }

    }

    function fetchWarehouses() {
      processing.value = true;
      http.get(`/api/warehouses?fetch=all`).then(res => {
        warehouses.value = res.data;
        processing.value = false;
      });
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchStock(f: any, start: any, end: any) {
      const start_prev = moment(start).subtract(1, "days").format("YYYY-MM-DD");
      const res = await http.get(
        `/api/stock?warehouse_id=${f}&start=${start_prev}&end=${end}`
      );
      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;

      fetchStock(
        id, start.value,
        fD(end.value, "yyyy-MM-dd")
      ).then((ps: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          const dates = getDates(start.value);
          const start_prev = moment(start.value).subtract(1, "days").format("YYYY-MM-DD");

          dates.unshift(start_prev)

          form.value = dates.reduce((o: any, d: any) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected:
                      ((p?.quantities as any)[v.id] as any)?.projected || 0,
                    packed_ahead:
                        ((p?.quantities as any)[v.id] as any)?.packed_ahead || 0,
                    packed_behind:
                        ((p?.quantities as any)[v.id] as any)?.packed_behind || 0,
                    records: ((p?.quantities as any)[v.id] as any)?.records,
                    stock_records_present:
                        ((p?.quantities as any)[v.id] as any)?.stock_records_present || false,
                  }
                }),
                {}
              )
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function sumStock(v: any) {
      return getDates(start.value).reduce((o, d) => {
        const stock: any = (form.value as any)[d];
        const p = stock[v];
        return o + Number(p.projected || 0);
      }, 0);
    }

    function isFilled(v: any) {
      const total = getDates(start.value).reduce((o, d) => {
        const stock: any = (form.value as any)[d];
        const p = stock[v];
        return o + Number(p.projected || 0);
      }, 0);

      return reports.value ? total > 0 : true;
    }

    function searchVarieties() {
      fetchStock(
        warehouse.value,
        fD(start.value, "yyyy-MM-dd"),
        fD(end.value, "yyyy-MM-dd")
      ).then((ps: any) => {
        http.get(`/api/varieties?s=${search.value}`).then(res => {
          const a = res.data.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = getDates(start.value).reduce((o, d) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected: (p?.quantities as any)[v.id]["projected"] || 0
                  }
                }),
                {}
              )
            };
          }, {});
        });
      });
    }

    function saveStock() {
      loading.value = true;
      http
        .post("/api/stock", {
          warehouse_id: warehouse.value,
          stock: form.value,
          start: start.value,
          end: end.value
        })
        .then(res => {
          if (res.data.success) {
            loading.value = false;
            created.value = true;
          }
        })
        .finally(() => {
          loading.value = false;
          fetchVarieties(warehouse.value);
        });
    }

    function saveStockForward(){
      const date_to_use = nextDay(prev_date.value)
      const id_to_use = selected_variety.value.id
      const records_to_use = forward_stock.value
      const form_to_use = form.value

      Object.keys(form_to_use).forEach(function (key) {
        if(key === date_to_use) {
          const quantities = form_to_use[key]
          Object.keys(quantities).forEach(function (key2) {
            if(key2 == id_to_use){
              records_to_use.forEach((record: any) => quantities[key2]['records'].push(record));

            }
          })
        }
      })
      saveStock()
      document.getElementById('closeForwardModal')?.click();
    }

    function updateAssignment(assignment: any) {
      assignment._method = "PUT";
      http
        .post(`/api/varieties/${assignment.id}`, assignment)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchVarieties(warehouse.value);
        });
    }

    function deleteAssignment(id: any) {
      http.post(`/api/varieties/${id}`, { _method: "DELETE" }).then(res => {
        fetchVarieties(warehouse.value);
      });
    }

    function selectStockByVariety(variety: any, date: any) {
      prev_date.value = prevDay(date);
      selected_variety.value.id = variety.id;
      selected_variety.value.name = variety.name;
      forward_stock.value = []
    }

    onMounted(() => {
      fetchWarehouses();
      fetchFarms();

      if (router.currentRoute.value.query?.farm_id) {
        const f = router.currentRoute.value.query?.farm_id;

        warehouse.value = Number(f);
        fetchVarieties(f);
      } else {
        warehouse.value = 1;
        fetchVarieties(1);
      }
    });

    watch(
      () => start.value,
      () => {
        console.log('start value change')
        changePrevDate(start.value)
        fetchVarieties(warehouse.value);
      }
    );

    function updateForm() {
      fetchVarieties(warehouse.value);
    }

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      saveStock,
      created,
      assignment,
      updateAssignment,
      updated,
      deleteAssignment,
      fD,
      getDates,
      start,
      end,
      form,
      warehouse,
      warehouses,
      warehouseName,
      updateForm,
      sumStock,
      isFilled,
      reports,
      loading,
      day,
      setDay,
      prod,
      setProd,
      farms,
      addRep,
      delRep,
      selectStockByVariety,
      prevDay,
      prev_date,
      selected_variety,
      moveStockForward,
      forward_stock,
      saveStockForward,
      getFarmName,
      tosave,
      start_prev,
      addNewRep
    };
  }
});
</script>
